import { Box, Button, Stack, Typography, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Layout } from "../dashboard/dashboard";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DataTable from "./categoryTable";
import CustomDialogue from "../reuseableComp/Modal";
import { Typo } from "../payment";
import { CustomField } from "../client";
import { PersonLogo } from "../../assets/Svg";
import * as yup from "yup";
import {
  getCategories,
  saveCategories,
  updateCategory,
} from "../../apiservices";
import { toast } from "react-toastify";
const Btn = styled(Button)({
  minWidth: "126px",
  height: "36px",
  padding: "4px",
  borderRadius: "4px",
  textTransform: "none",
  background: "#FFFFFF",
  color: "#05ae97",
  marginRight: 10,
  "&:hover": {
    background: "#F6F6F6",
  },
  "&:active": {
    background: "#FFFFFF",
  },
});
export const schema = yup
  .object({
    fName: yup.string().required(),
  })
  .required();

const CategoryComp = () => {
  const [state, setState] = useState({
    openModal: false,
    categoryData: [],
    check: false,
    updatedData: [],
    brandName: [],
    categoryName: "",
    selectedBrand: "",
    brand: "",
    searchData: [],
    textField: false,
  });

  const handleModal = (data = "") => {
    setState((prev) => ({
      ...prev,
      openModal: !prev.openModal,
      check: false,
      updatedData: data,
      categoryName: data.category_name,
      brand: data.brand_name,
    }));
  };
  const getCategory = async () => {
    const userData = JSON.parse(localStorage.getItem("user"));

    let obj = {
      company_code: userData.company_code,
    };
    let res = await getCategories(obj);
    if (res.success) {
      setState((prev) => ({
        ...prev,
        categoryData: res.data
      }));
    }
    else{
      setState((prev) => ({
        ...prev,
        categoryData: []
      }));
    }
  };
  useEffect(() => {
    getCategory();
  }, []);
  const handleChange = (e) => {
    let { value } = e.target;
    let { name } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };
  const onSubmit = async () => {
    const userData = JSON.parse(localStorage.getItem("user"));

    if (state.check) {
      let obj = {
        // company_code: userData.company_code,
        brand_name: state.brand,
        category_name: state.categoryName,
        updated_by: userData.id,
      };
      let res = await updateCategory(obj, state.updatedData.id);
      if (res) {
        toast.success("Category updated successfully");
        getCategory();
        handleModal();
      }
    } else {
      let obj = {
        // company_code: userData.company_code,
        brand_name: state.brand,
        category_name: state.categoryName,
        added_by: userData.id
      };
      let res = await saveCategories(obj);
      if (res.success) {
        toast.success("Category saved successfully");
        getCategory();
        handleModal();
      }
    }
  };
  const handleCheck = () => {
    setState((prev) => ({ ...prev, check: !prev.check }));
  };

  const updateData = (data) => {
    setState((prev) => ({ ...prev, categoryData: data }));
  };

  return (
    <Stack sx={{ paddingLeft: 2, paddingRight: 2 }}>
      <>
        <Layout
          justifyContent="space-between"
          alignItems={"center"}
          direction={"row"}>
          <Typography
            sx={{
              color: "#000",
              fontSize: "24px",
              fontWeight: 700,
            }}>
          </Typography>
          <Typography sx={{ color: "#000", fontSize: "24px", fontWeight: 700 }}>
            Categories
          </Typography>
          <Btn onClick={handleModal}>
            <AddCircleIcon fontSize="small" sx={{ mx: 1 }} /> Add new Category
          </Btn>
        </Layout>
        <Stack sx={{ padding: "20px" }}>
          {/* <Stack
            justifyContent={"start"}
            alignItems={"start"}
            sx={{
              width: "50%",
            }}>
            <Search
              data={state.searchData}
              updateData={(data) => updateData(data)}
              place1={" Search by Product name"}
              place2={""}
            />
          </Stack> */}
          <DataTable
            editProvider={handleModal}
            data={state.categoryData}
            handleCheck={handleCheck}
            getData={getCategory}
          />
        </Stack>
      </>
      <CustomDialogue
        open={state.openModal}
        handleClose={handleModal}
        header={
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Box sx={{ color: "#05ae97" }}>
              <PersonLogo />
            </Box>
            <Typo>Add new Category</Typo>
          </Stack>
        }
        content={
          <Stack sx={{ padding: 1 }}>
            <Stack direction={"row"} gap={2} sx={{ mb: 2 }}>
              <CustomField
                fullWidth
                label="Brand Name"
                value={state.brand}
                name="brand"
                onChange={handleChange}
                sx={{
                  background: "#F6F6F6",
                  "& label.Mui-focused": {
                    color: "#05ae97",
                  },
                }}
              />
            </Stack>
            <Stack direction={"row"} gap={2} sx={{ mb: 2 }}>
              <CustomField
                fullWidth
                // id="outlined-select-currency"
                label="Category Name"
                value={state.categoryName}
                name="categoryName"
                onChange={handleChange}
                sx={{
                  background: "#F6F6F6",
                  "& label.Mui-focused": {
                    color: "#05ae97",
                  },
                }}
              />
            </Stack>
            <Stack spacing={3} direction={"row"}>
              <Button
                onClick={handleModal}
                size="small"
                sx={{
                  background: "#FFF",
                  width: "200px",
                  textTransform: "none",

                  color: "#9F9E9E",
                  "&:hover": {
                    background: "#EFEFEF",
                  },
                }}>
                Cancel
              </Button>
              <Button
                onClick={onSubmit}
                size="small"
                sx={{
                  width: "200px",
                  textTransform: "none",
                  background: "#05ae97",
                  color: "#000",
                  "&:hover": {
                    background: "#61D140",
                  },
                }}>
                {state.check ? "update category" : " save category"}
              </Button>
            </Stack>
          </Stack>
        }
      />
    </Stack>
  );
};

export default CategoryComp;
