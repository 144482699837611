import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, styled } from "@mui/material";
import { deleteSupplier } from "../../apiservices";
import { toast } from "react-toastify";
import { CustomToolbar } from "../categories/categoryTable";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const Btn = styled(Button)({
  textTransform: "none",
  background: "#EEF4FF",
  color: "#05ae97",
  "&:hover": {
    color: "#000",
    background: "#05ae97",
  },
});

export default function DataTable({
  editSupplier,
  data,
  handleCheck,
  getData,
  viewLedger,
}) {
  const columns = [
    { field: "name", headerName: "Name", width: 250 },
    { field: "contact", headerName: "Mobile Number", width: 150 },
    // {
    //   field: "city_name",
    //   headerName: "City",
    //   width: 150,
    // },
    {
      field: "address",
      headerName: "Address",
      width: 300,
    },
    // {
    //   field: "opening_balance",
    //   headerName: "Opening Balance",
    //   width: 200,
    // },
    {
      field: "balance",
      headerName: "Balance",
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      sortable: false,
      renderCell: (params) => (
        <>
          <Btn
            sx={{ height: '30px', width: '100px', mr: '5px', padding: 0 }}
            variant="contained"
            color="primary"
            size="small"
            onClick={() => viewLedger(params.row)}>
            View Ledger
          </Btn>
          <Btn
            title="Edit"
            variant="contained"
            sx={{ width: '30px', height: '30px', minWidth: '30px', mr: '5px', padding: 0, background: "#eed202", color: "white" }}
            onClick={() => edit(params.row)}>
            <EditIcon fontSize="small" />
          </Btn>
          <Btn
            title="Delete"
            sx={{ width: '30px', height: '30px', minWidth: '30px', mr: '5px', padding: 0, background: "red", color: "white" }}
            variant="contained"
            onClick={() => deleteRow(params.row)}>
            <DeleteIcon fontSize="small" />
          </Btn>
        </>
      ),
    },
  ];

  const rows = data;
  const edit = (params) => {
    editSupplier(params);
    handleCheck();
  };
  const deleteRow = async (params) => {
    /* eslint-disable no-restricted-globals */
    if (confirm(`Are you sure to delete "${params.provider_name}"`)) {
      let res = await deleteSupplier(params.id);
      if (res) {
        toast.error("Supplier deleted successfully");
        getData();
      }
    }
  };
  return (
    <div style={{ height: "80vh", width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 20 },
          },
        }}
        pageSizeOptions={[20, 50]}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
    </div>
  );
}
