import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, styled } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { AppReg } from "../../assets/Svg";
import { toast } from "react-toastify";
import {
  deletePayments,
  deleteSpecificPaymentFromClient,
  getClientById,
} from "../../apiservices";
import { CustomToolbar } from "../categories/categoryTable";
import EditIcon from "@mui/icons-material/Edit";

const Btn = styled(Button)({
  textTransform: "none",
  background: "#EEF4FF",
  color: "#05ae97",
  "&:hover": {
    color: "#000",
    background: "#05ae97",
  },
});

export default function DataTable({
  editPayment,
  view,
  viewLedger,
  data,
  getData,
  handleCheck,
  clientData,
}) {
  let user = localStorage.getItem("user");
  let parsed = JSON.parse(user);
  // if (data) {
  //   data.forEach((element) => {
  //     element["id"] = element?.paymentId;
  //     let filtered = clientData.filter((item) => item._id == element?.payerId);
  //     element["clientName"] = filtered && filtered[0]?.clientName;
  //   });
  // }
  const columns = [
    { field: "date", headerName: "Date" },
    { field: "show_id", headerName: "Payment#", width: 130 },
    { field: "client_name", headerName: "Client", width: 200 },
    {
      field: "transaction_mode",
      headerName: "Transaction Mode",
      sortable: true,
      width: 150,
    },
    { field: "amount", headerName: "Amount", width: 150 },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      sortable: false,
      renderCell: (params) => (
        <>
          <Btn
            sx={{ height: '30px', width: '150px', mr: '5px', padding: 0 }}
            variant="contained"
            color="primary"
            size="small"
            onClick={() => view(params.row)}>
            View Payment
          </Btn>
          <Btn
            title="Edit"
            variant="contained"
            sx={{ width: '30px', height: '30px', minWidth: '30px', mr: '5px', padding: 0, background: "#eed202", color: "white" }}
            onClick={() => edit(params.row)}>
            <EditIcon fontSize="small" />
          </Btn>
          <Btn
            title="Delete"
            sx={{ width: '30px', height: '30px', minWidth: '30px', mr: '5px', padding: 0, background: "red", color: "white" }}
            variant="contained"
            onClick={() => deleteRow(params.row)}>
            <DeleteIcon fontSize="small" />
          </Btn>
          {/* You can add more buttons or customize based on your requirements */}
        </>
      ),
    }
  ];

  const rows = data ? data : [];
  const edit = (params) => {
    editPayment(params);
    // handleCheck();
  };
  const deleteRow = async (params) => {
    /* eslint-disable no-restricted-globals */
    if(confirm(`Are you sure to delete payment of "${params.amount}"`)){
      let res = await deletePayments(params.id);
      if (res) {
        toast.error("Payment deleted successfully");
        getData();
      }
    }
  };
  return (
    <div style={{ height: "80vh", width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 20 },
          },
        }}
        pageSizeOptions={[20, 50]}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
    </div>
  );
}
