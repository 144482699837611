import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, styled } from "@mui/material";
import { deleteProduct } from "../../apiservices";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { CustomToolbar } from "../categories/categoryTable";

const Btn = styled(Button)({
  textTransform: "none",
  background: "#EEF4FF",
  color: "#05ae97",
  "&:hover": {
    color: "#000",
    background: "#05ae97",
  },
});

export default function DataTable({
  editProduct,
  data,
  handleCheck,
  getData,
  openDetail,
}) {
  // if (data) {
    // data.forEach((element) => {
    //   element["id"] = element._id;
    // });
  // }
  const columns = [
    // { field: "brand_name", headerName: "Brand" },
    // { field: "category_name", headerName: "Category", width: 130},
    { field: "description", headerName: "Products", width: 300 },
    // { field: "modelName", headerName: "Model", width: 150 },
    {
      field: "purchase_price",
      headerName: "Purchase Price",
      width: 150,
    },
    {
      field: "sale_price",
      headerName: "Sale Price",
      sortable: true,
      width: 150,
    },
    // {
    //   field: "opening_qty",
    //   headerName: "Opening Qty",
    //   sortable: true,
    //   width: 150,
    // },
    {
      field: "stock",
      headerName: "Stock",
      sortable: true,
      width: 150,
    },
    {
      field: "action",
      headerName: "Action",
      width: 250,
      sortable: false,
      renderCell: (params) => (
        <>
          <Btn
            sx={{ height: '30px', mr: '5px', padding: 0 }}
            variant="contained"
            color="primary"
            size="small"
            onClick={() => openDetail(params.row)}>
            Details
          </Btn>
          <Btn
            title="Edit"
            variant="contained"
            sx={{ width: '30px', height: '30px', minWidth: '30px', mr: '5px', padding: 0, background: "#eed202", color: "white" }}
            onClick={() => edit(params.row)}>
            <EditIcon fontSize="small" />
          </Btn>
          <Btn
            title="Delete"
            sx={{ width: '30px', height: '30px', minWidth: '30px', mr: '5px', padding: 0, background: "red", color: "white" }}
            variant="contained"
            onClick={() => deleteRow(params.row)}>
            <DeleteIcon fontSize="small" />
          </Btn>
        </>
      ),
    },
  ];

  const rows = data;
  const edit = (params) => {
    editProduct(params);
    handleCheck();
  };
  
  const deleteRow = async (params) => {
    /* eslint-disable no-restricted-globals */
    if(confirm(`Are you sure to delete "${params.description}"`)){
      let res = await deleteProduct(params.id);
      if (res) {
        toast.error("Product deleted successfully");
        getData();
      }
    }
  };
  return (
    <div style={{ height: "80vh", width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 20 },
          },
        }}
        pageSizeOptions={[20, 50]}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
    </div>
  );
}
